<template>
  <div class="tab-content" id="PTtab-5" :key="'PTtab-5'">
    <ul class="tablist">
      <li class="tabmenu current" @click="onChangeTab('partnerCashList')">{{ $t('front.mypage.depositWithdrawalList') }}</li>
      <li class="tabmenu" @click="onChangeTab('partnerMoneyMoveList')">{{ $t('front.stributor.m5') }}/{{ $t('front.common.retriDetail') }}</li>
    </ul>
      <div class="pagenamPT">
        <h3 class="pagename2">{{ $t('front.mypage.depositWithdrawalList') }}</h3>
      </div>
      <div class="sch_PC">
        <div class="PTsch flex-c">
          <div class="datesearchPTWarp flex-c gap-2">
            <h4>{{ $t('front.give.date') }}</h4>
            <date-filter :retail="true" @search="loadData(null, 1)"
                         @update="onChangeDateTable"
                         :defaultDay="0"
                         :startDate="mainTableDate.startDate"
                         :endDate="mainTableDate.endDate"
                         :isOldYn="true"
                         :id="'main-date-checkbox1'"
                         @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
          </div>
        </div>
      </div>
      <div class="sch_M">
        <div class="PTsch flex-c">
          <div class="datesearchM">
            <date-filter-mobile :retail="true" @search="loadData(null, 1)"
                               @update="onChangeDateTable"
                               :defaultDay="0"
                               :startDate="mainTableDate.startDate"
                               :endDate="mainTableDate.endDate"
                               :isOldYn="true"
                               :id="'main-date-checkbox1'"
                               @setOldYn="setOldYn" :oldYn="reqData.oldYn"
           />
         </div>
        </div>
      </div>
      <div class="sch_Normal">
        <div class="sch_Btm">
          <div class="searchPTwrap">
            <div class="searchPT">
              <div class="datesearchPT flex-c gap-2">
                <h4>{{ $t('front.board.kind') }}</h4>
                <select  v-model="reqData.cashType" class="h36px">
                  <option value="" selected>{{ $t('front.gameCategory.all') }}</option>
                  <option value="1">{{ $t('front.common.deposit') }}</option>
                  <option value="-1">{{ $t('front.common.withdrawal') }}</option>
                  <option value="2">{{ $t('front.common.managerPay') }}</option>
                  <option value="-2">{{ $t('front.common.managerRetri') }}</option>
                  <option value="33">{{ $t('front.common.upper') }}</option>
                  <option value="-33">{{ $t('front.common.upperRetri') }}</option>
                  <option value="4">{{ $t('front.point.pointTrans') }}</option>
                </select>
              </div>
              <div class="datesearchPT flex-c gap-2">
                <h4>{{ $t('front.board.type') }}</h4>
                <select  v-model="reqData.searchType" class="h36px">
                  <option value="memId" selected>{{ $t('front.common.memId') }}</option>
                  <option value="memNick">{{ $t('front.common.nickName') }}</option>
                  <option value="recommenderId">{{ $t('front.common.topUser') }}</option>
                </select>
              </div>
            </div>
            <div class="searchPT">
              <input type="text" :placeholder="$t('front.search.emptySearch')"  v-model="reqData.searchWord" class="h36px"/>
              <a @click="loadData(null,1)" class="sch_Icon">
                <img src="@/assets/img/search.png" alt=""/>
              </a>
            </div>
          </div>
          <div class="searchPTwrap">
            <div class="datesearchPT flex-c gap-2 w30p">
              <h4>{{ $t('front.common.order') }}</h4>
              <select v-model="orderStr" class="h36px">
                <option value="preCashAmt_DESC">{{ $t('front.common.largeProcess') }}</option>
                <option value="preCashAmt_ASC">{{ $t('front.common.smallProcess') }}</option>
                <!-- <option value="cashAmt_DESC">처리금액 많은순</option>
                <option value="cashAmt_ASC">처리금액 적은순</option> -->
                <option value="regDate_ASC">{{ $t('front.common.Appliearliest') }}</option>
                <option value="regDate_DESC">{{ $t('front.common.Applilatest') }}</option>
                <option value="updDate_ASC">{{ $t('front.common.processEarly') }}</option>
                <option value="updDate_DESC">{{ $t('front.common.processLatest') }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="strTableWrap">
        <div class="subMemWrap">
          <p class="p10"></p>
          <ul class="subPT_1">
            <h3>
              {{ $t('front.common.lowerPartnerList') }}
              <a class="allBtn" @click="loadData(userData.memId, 1)">{{ $t('front.common.fullList') }}</a>
              <!-- <a @click="idlist=!idlist">i</a> -->
              <span v-if="idlist" @click="idlist=!idlist">아이디 클릭시 목록 출력</span>
            </h3>
            <!-- <li>
              <a>
                <span class="box" :class="userData.partnerLevel">{{partnerLevelName(userData.partnerLevel)}}</span>
                {{userData.memId}}
              </a>
            </li> -->
            <li v-for="(item1) in partnerList" :key="item1.memId" :class="{'morebar':item1.open}">
              <span class="arr" v-if="item1.cnt" :class="[item1.partnerLevel, {'moreOn':item1.open}]" @click="listOpen(item1.memId, 'sub', item1)"></span>
              <span class="arr normal" v-if="!item1.cnt"></span>
              <a :class="[item1.partnerLevel, { 'active': selectedLi === item1.memId }]" @click="getMyPartnerList(item1.memId, 'sub', item1)">
                <span class="box" :class="item1.partnerLevel">{{ $t('front.common.hq') }}</span>
                {{item1.memId}}
              </a>

              <ul class="subPT_2" v-show="item1.open && item1.children">
                <li v-for="item2 in item1.children" :key="item2.memId" :class="{'morebar':item2.open}">
                  <span class="arr" v-if="item2.cnt" :class="[item2.partnerLevel,{'moreOn':item2.open}]" @click="listOpen(item2.memId, 'sub', item2)"></span>
                  <span class="arr normal" v-if="!item2.cnt"></span>
                  <a :class="[item2.partnerLevel, { 'active': selectedLi === item2.memId }]" @click="getMyPartnerList(item2.memId, 'sub', item2)">
                    <span class="box" :class="item2.partnerLevel">{{ $t('front.common.cp') }}</span>
                    {{item2.memId}}
                  </a>

                  <ul class="subPT_3" v-show="item2.open && item2.children">
                    <li v-for="item3 in item2.children" :key="item3.memId" :class="{'morebar':item3.open}">
                      <span class="arr" v-if="item3.cnt" :class="[item3.partnerLevel,{'moreOn':item3.open}]" @click="listOpen(item3.memId, 'sub', item3)"></span>
                      <span class="arr normal" v-if="!item3.cnt"></span>
                      <a :class="[item3.partnerLevel, { 'active': selectedLi === item3.memId }]" @click="getMyPartnerList(item3.memId, 'sub', item3)">
                        <span class="box" :class="item3.partnerLevel">{{ $t(partnerLevelName(item3.partnerLevel)) }}</span>
                        {{item3.memId}}
                      </a>

                      <ul class="subPT_4" v-show="item3.open && item3.children">
                        <li v-for="item4 in item3.children" :key="item4.memId" :class="{'morebar':item4.open}">
                          <span class="arr" v-if="item4.cnt" :class="[item4.partnerLevel,{'moreOn':item4.open}]" @click="listOpen(item4.memId, 'sub', item4)"></span>
                          <span class="arr normal" v-if="!item4.cnt"></span>
                          <a :class="[item4.partnerLevel, { 'active': selectedLi === item4.memId }]" @click="getMyPartnerList(item4.memId, 'sub', item4)">
                            <span class="box" :class="item4.partnerLevel">{{ $t(partnerLevelName(item4.partnerLevel)) }}</span>
                            {{item4.memId}}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <!-- <ul class="subPT_1">
            <template v-for="(item1) in partnerList" :key="item1.memId">
              <li>
                <span v-if="item1.cnt" :class="{'moreOn':item1.children && item1.children.length > 0}" class="arr">▶</span>
                <a :class="[item1.partnerLevel, { 'active': selectedLi === item1.memId }]" @click="getMyPartnerList(item1.memId, 'sub', item1)">
                  <span class="box" :class="item1.partnerLevel">{{partnerLevelName(item1.partnerLevel)}}</span>
                  {{item1.memId}}
                </a>
                <ul class="subPT_2" v-show="item1.open && item1.children">
                  <template v-for="(item2) in item1.children" :key="item2.memId">
                    <li>
                      <span v-if="item2.cnt" :class="{'moreOn':item2.children && item2.children.length > 0}" class="arr">▶</span>
                      <a :class="[item2.partnerLevel, { 'active': selectedLi === item2.memId }]" @click="getMyPartnerList(item2.memId, 'sub', item2)">
                        <span class="box" :class="item2.partnerLevel">{{partnerLevelName(item2.partnerLevel)}}</span>
                        {{item2.memId}}
                      </a>
                      <ul class="subPT_3" v-show="item2.open && item2.children">
                        <template v-for="(item3) in item2.children" :key="item3.memId">
                          <li>
                            <span v-if="item3.cnt" :class="{'moreOn':item3.children && item3.children.length > 0}" class="arr">▶</span>
                            <a :class="[item3.partnerLevel, { 'active': selectedLi === item3.memId }]" @click="getMyPartnerList(item3.memId, 'sub', item3)">
                              <span class="box" :class="item3.partnerLevel">{{partnerLevelName(item3.partnerLevel)}}</span>
                              {{item3.memId}}
                            </a>
                            <ul class="subPT_4" v-show="item3.open && item3.children">
                              <template v-for="(item4) in item3.children" :key="item4.memId">
                                <li>
                                  <span v-if="item4.cnt" :class="{'moreOn':item4.children && item4.children.length > 0}" class="arr">▶</span>
                                  <a :class="[item4.partnerLevel, { 'active': selectedLi === item4.memId }]" @click="getMyPartnerList(item4.memId, 'sub', item4)">
                                    <span class="box" :class="item4.partnerLevel">{{partnerLevelName(item4.partnerLevel)}}</span>
                                    {{item4.memId}}
                                  </a>
                                </li>
                              </template>
                            </ul>
                          </li>
                        </template>
                      </ul>
                    </li>
                  </template>
                </ul>
              </li>
            </template>
          </ul> -->
        </div>
        <div class="strTablescr">
         <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
                <col style="width:9%;">
                <col style="width:9%;">
                <col style="width:3%;">
                <col style="width:10%;">
                <col style="width:10%;">
                <col style="width:10%;">
                <col style="width:10%;">
                <col style="width:10%;">
                <col style="width:13%;">
                <col style="width:13%;">
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('front.common.memId') }}</th>
                  <th>{{ $t('front.common.nickName') }}</th>
                  <th>{{ $t('front.common.rank') }}</th>
                  <th>{{ $t('front.common.department') }}</th>
                  <th>{{ $t('front.board.kind') }}</th>
                  <!-- <th>상위아이디</th> -->
                  <th>{{ $t('front.common.beforeProcess') }}</th>
                  <th>{{ $t('front.common.processingAmount') }}</th>
                  <th>{{ $t('front.common.afterProcess') }}</th>
                  <th>{{ $t('front.common.appliedTime') }}</th>
                  <th>{{ $t('front.common.processTime') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="cashList.length > 0">
                  <template v-for="(item, idx) in cashList" :key="item.updDate + idx">
                    <tr>
                      <td>{{item.memId}}</td>
                      <td><span class="nick">{{item.memNick}}</span></td>
                      <td>
                        <span class="box" :class="item.myLevel">{{ partnerLevelName(item.myLevel) }}</span>
                      </td>
                      <td>
                        <div class="topwrap" v-if="item.topUserList && item.topUserList.length > 0 && item.topUserList !== '0'">
                          <select class="h20 upperUser">
                            <option v-for="pt in item.topUserList" :key="pt.recommenderId">
                                <span>[{{ $t(pt.partnerLevelName) }}] </span>
                                <span>{{pt.recommenderId}}</span>
                            </option>
                          </select>
                          <i class="icon" @click="onOpenRate(item)">+</i>
                        </div>
                        <div v-else>
                          -
                        </div>
                      </td>
                      <td>{{item.cashDesc}}</td>
                      <td>{{thousand(item.preCashAmt)}}</td>
                      <td :class="{'minusAmt' : item.cashDesc === '환전', 'plusAmt' : item.cashDesc === '충전', }">{{thousand(item.cashAmt.replace('-', ''))}}</td>
                      <td>{{thousand(item.cashAmt-(-item.preCashAmt))}}</td>
                      <td>{{dateFormatAll(item.regDate)}}</td>
                      <td>{{dateFormatAll(item.updDate)}}</td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                  </tr>
                </template>
              </tbody>
            </table>
         </div>
         <div class="strTableM">
            <div class="strTablePTM">
              <template v-if="cashList.length > 0">
                <template v-for="item in cashList" :key="item.updDate">
                  <ul>
                    <li>
                      <em>아이디</em>
                      <div>{{item.memId}}</div>
                    </li>
                    <li>
                      <em>닉네임</em>
                      <div class="nick">{{item.memNick}}</div>
                    </li>
                    <li>
                      <em>등급</em>
                      <div><span class="box" :class="item.myLevel">{{partnerLevelName(item.myLevel)}}</span></div>
                    </li>
                    <li>
                      <em>소속</em>
                      <div>
                        <div class="topwrap" v-if="item.topUserList && item.topUserList.length > 0 && item.topUserList !== '0'">
                          <select class="h20 upperUser mw-auto">
                            <option v-for="pt in item.topUserList" :key="pt.recommenderId">
                                <span>[{{pt.partnerLevelName}}] </span>
                                <span>{{pt.recommenderId}}</span>
                            </option>
                          </select>
                          <i class="icon">+</i>
                        </div>
                        <div v-else>
                          -
                        </div>
                      </div>
                    </li>
                    <!-- <li><em>상위 아이디</em>{{item.recommenderId}} / {{item.recommenderNick}}</li> -->
                    <li>
                      <em>처리 전</em>
                      <div>{{thousand(item.preCashAmt)}}</div>
                    </li>
                    <li>
                      <em>구분</em>
                      <div>{{item.cashDesc}}</div>
                    </li>
                    <li>
                      <em>처리 금액</em>
                      <div>{{thousand(item.cashAmt.replace('-', ''))}}</div>
                    </li> <!--마이너스 파란색 플러스 빨간색-->
                    <li>
                      <em>신청 시각</em>
                      <div>{{dateFormatAll(item.regDate)}}</div>
                    </li>
                    <li>
                      <em>처리 후</em>
                      <div>{{thousand(item.cashAmt-(-item.preCashAmt))}}</div>
                    </li>
                    <li>
                      <em>처리 시각</em>
                      <div>{{dateFormatAll(item.updDate)}}</div>
                    </li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <ul>
                  <li class="nodata">내역 없음</li>
                </ul>
              </template>
            </div>
         </div>
      </div>
      </div>
      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="goToPage"
      />
      <transition name="fade">
        <RateTable v-show="RateOpen" :isOpen="onOpenRate" @close="onClosePay"/>
      </transition>
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import RateTable from '@/components/common/RateTable.vue'
import Pagination from '@/components/ui/Pagination.vue'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import { partnerLevels, retailMyPartnerCashList, retailMyPartnerDirectList } from '@/api/retail'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'
export default {
  name: 'PartnerCash',
  components: { DateFilter, DateFilterMobile, Pagination, RateTable },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      mainTableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      reqData: {
        memId: '',
        cashType: '',
        searchType: '',
        searchWord: '',
        startDate: '',
        endDate: '',
        oldYn: 'N'
      },
      partnerList: [],
      cashList: [],
      selectedLi: null,
      idlist: false,
      RateOpen: false,
      orderStr: ''
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.getMyPartnerList(this.userData.memId)
    this.reqData.startDate = getDateStr(new Date(this.mainTableDate.startDate))
    this.reqData.endDate = getDateStr(new Date(this.mainTableDate.endDate))
    await this.loadData(this.userData.memId, 1)
    this.emitter.emit('Loading', false)
  },
  watch: {
    orderStr () {
      this.loadData(this.userData.memId, 1)
    }
  },
  methods: {
    onClosePay () {
      this.RateOpen = false
    },
    onOpenRate () {
      this.RateOpen = true
    },
    thousand,
    goToPage (page) {
      this.loadData(this.selectedLi, page)
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    partnerLevelName (partnerLevel) {
      return PARTNER_LEVEL_NAME[partnerLevel]
    },
    onChangeTab (name, param) {
      console.log('onChangeTab : ', name, param)
      if (param && param.code) {
        console.log('onChangeTab param : ', param)
        this.currentTab = param.code
      } else {
        this.currentTab = name
      }
      this.goPageByName(name, param)
    },
    listOpen (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      retailMyPartnerDirectList(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list
            item.open = !item.open
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getMyPartnerList (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      retailMyPartnerDirectList(params, type).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list

            if (this.selectedLi === memId) {
              this.selectedLi = memId
            } else {
              this.selectedLi = memId
            }

            this.loadData(memId, 1, type)
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]
          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangeDateTable (value) {
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    async loadData (memId, page, type) {
      if (!page) {
        page = this.pageInfo.page
      } else {
        this.pageInfo.page = page
      }
      this.emitter.emit('Loading', true)

      if (memId) {
        this.reqData.memId = memId
      }

      const params = { ...this.reqData, page: page, count_per_list: 40 }
      // console.log('데이터 ::::', params)
      if (this.orderStr) {
        const orderColumn = this.orderStr.split('_')[0]
        const orderType = this.orderStr.split('_')[1]

        params.orderColumn = orderColumn
        params.orderType = orderType
      }

      await retailMyPartnerCashList(params, type).then(res => {
        this.emitter.emit('Loading', false)
        console.log('cashlist : ', res)
        const data = res.data.data
        this.cashList = []

        if (data) {
          this.cashList = data.list

          if (data.pageInfo) {
            this.pageInfo = data.pageInfo
          } else {
            this.pageInfo.page = 1
            this.pageInfo.tatal_list_count = 0
          }
        }
      })
    }
  }
}
</script>

<style src="@/styles/striNew.css"></style>
<style scoped>
.flex-c {
  flex-direction: column;
}
.gap-2 {
  gap: 2px;
}
@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
}
.subPT_1 li {
  position: relative;
}
.allBtn {
  background: #e50000;
  color: #fff;
  width: 57px;
  height: 20px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #000;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
</style>
